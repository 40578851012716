<template>
  <vx-card>
    <form>
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.code') }} & {{ $t('fields.priority') }}</strong></legend>
          <div class="vx-col my-2 md:w-1/2 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="code" :placeholder="$t('fields.code')" v-model="category.code" />
            <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
          </div>
          <div class="vx-col my-2 md:w-1/2 w-full">
            <vs-input v-validate="'required'" class="w-full" type="number" name="priority" :placeholder="$t('fields.priority')" v-model="category.priority" />
            <span class="text-danger text-sm" v-show="errors.has('priority')">{{ errors.first('priority') }}</span>
          </div>
        </fieldset>
      </div>
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.title') }}</strong></legend>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required'" class="w-full" name="name_tm" placeholder="Turkmen" v-model="category.name_tm" />
            <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
          </div>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="name_ru" placeholder="Russian" v-model="category.name_ru" />
            <span class="text-danger text-sm" v-show="errors.has('name_ru')">{{ errors.first('name_ru') }}</span>
          </div>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="name_en" placeholder="English" v-model="category.name_en" />
            <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first('name_en') }}</span>
          </div>
        </fieldset>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <span><strong>{{ $t('select') }} {{ $t('brands') }}</strong></span>
          <v-select :options="brands" :label="`name_${$i18n.locale}`" name="brands" :placeholder="$t('select') + ' ' + $t('brands')" multiple v-model="category.brands" :reduce="brand => brand.id" />
          <span class="text-danger text-sm" v-show="errors.has('brands')">{{ errors.first('brands') }}</span>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col w-full">
          <span><strong>{{ $t('select') }} {{ $t('fields.type') }}</strong></span>
          <v-select v-validate="'required_if:canPublished,true'" :options="types" name="type" :placeholder="$t('selectOne')" v-model="category.type" :label="`name_${$i18n.locale}`" :reduce="opt => opt.value" />
          <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
      </div>
      <div class="mb-4">
        <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.properties') }}</strong></legend>
          <ul class="vx-row centerx px-2">
            <li class="vx-col my-4 ml-2 mr-4 flex">
              <vs-switch v-model="parent" />
              <label class="ml-3">{{$t('doesHasParentCat')}}?</label>
            </li>
            <li class="vx-col my-4 ml-2 mr-4 flex">
              <vs-switch v-model="category.isLeaf" />
              <label class="ml-3">{{$t('isLeafCat')}}?</label>
            </li>
            <li class="vx-col my-4 ml-2 mr-4 flex">
              <vs-switch name="isActive" ref="isActive" v-model="category.isActive" />
              <label class="ml-3">{{$t('isActive')}}?</label>
            </li>
            <li class="vx-col my-4 ml-2 mr-4 flex">
              <vs-switch v-validate="'required_if:isActive,true'" name="canPublished" ref="canPublished" v-model="category.canPublished" />
              <label class="ml-3" :class="errors.has('canPublished') ? 'text-danger' : ''">{{ $t('canBePublished') }}?</label>
            </li>
            <span class="px-3 text-danger text-sm" v-show="errors.has('canPublished')">{{ errors.first('canPublished') }}</span>
          </ul>
        </fieldset>
      </div>
      <div v-if="parent" class="vx-row mb-3">
        <div class="vx-col w-full">
          <span><strong>{{ $t('select') }} {{ $t('fields.parent') }}</strong></span>
          <v-select v-validate="'required'" :label="`name_${$i18n.locale}`" :options="categories" name="link" :placeholder="$t('selectOne')" v-model="category.parentId" :reduce="cat => cat.id" />
          <span class="text-danger text-sm" v-show="errors.has('link')">{{ errors.first('link') }}</span>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col upload-img mt-3" v-if="!category.photo">
          <input name="photo" type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()" type="border" icon-pack="feather" icon="icon-upload">{{ $t('upload') }} {{ $t('image') }}</vs-button>
          <span class="text-danger text-sm" v-show="errors.has('photo')">{{ errors.first('photo') }}</span>
        </div>
      </div>
      <template v-if="category.photo">
        <div class="img-container w-64 mx-auto flex items-center justify-center">
          <img :src="category.photo" alt="img" class="responsive">
        </div>
        <div class="modify-img flex justify-between mt-5">
          <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{ $t('update') }} {{ $t('image') }}</vs-button>
          <vs-button type="flat" color="#999" @click="category.photo = null">{{ $t('remove') }} {{ $t('image') }}</vs-button>
        </div>
      </template>
      <div class="vx-row px-4">
        <vs-button type="filled" @click.prevent="submitForm" class="ml-auto mt-5 block">{{$t('submit')}}</vs-button>
        <vs-button type="border" color="warning" @click.prevent="$router.back()" class="mr-auto ml-4 mt-5 block">{{$t('cancel')}}</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      category: {
        code: '',
        priority: 1,
        name_tm: '',
        name_ru: '',
        name_en: '',
        canPublished: false,
        isLeaf: false,
        parentId: null,
        isExpress: false,
        photo: null,
        type: null,
        isActive: false,
        brands: []
      },
      parent: false,
      selectLink:'',
      link: '',
      brands: [],
      categories: [],
      types: [
        {value: 'both', name_en: 'Both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {value: 'market', name_en: 'Market', name_tm: 'Market', name_ru: 'Маркет'},
        {value: 'express', name_en: 'Express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ]
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.$http.get('/categories', {
        params: { isLeaf: false, isActive: true }
      }).then(response => {
        this.categories = response.data.data
      }).catch(() => null)
      await this.$http.get('/brands').then(response => {
        this.brands = response.data.data
      }).catch(() => null)
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/categories/add', {
            ...this.category
          }).then(response => {
            if (response.status === 201) {
              this.notify('Success', this.$t('notify.success'), `${this.$t('category')} ${this.$t('notify.sCreated')}`)
              this.$router.push('/categories')
            }
          }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
        } else {
          this.notify('Error', this.$t('notify.error'), this.$t('notify.formInvalid'))
        }
      })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.category.photo = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'Success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'Success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>
